import React from "react";
import Layout from "../../components/layout";
import Search from "./search";

const Index = () => {
  return (
    <div>
      <Layout>
        <Search />
      </Layout>
    </div>
  );
};

export default Index;
